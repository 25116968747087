// src/components/TermsOfService.js
import React from "react";
import "./StaticPage.css";
import Footer from "./Footer";

function TermsOfService() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page">
        <h1>Terms of Service</h1>

        <p>
          These Terms of Service (“Terms”) constitute a legally binding agreement between you and YHatch regarding your access to and use of our website, digital services, and associated platforms. By accessing or using any part of YHatch, you confirm that you have read, understood, and agreed to be bound by these Terms.
        </p>

        <h2>1. Eligibility</h2>
        <p>
          YHatch is intended primarily for youth and teen founders. Users under the age of 18 must obtain consent from a parent or legal guardian. If you are registering on behalf of a minor, you affirm that you are legally authorized to provide such consent and are responsible for the minor’s use of the platform.
        </p>

        <h2>2. Account Registration and Security</h2>
        <p>
          To access certain features, users must create an account and provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your login credentials and for all activity under your account. YHatch reserves the right to suspend or terminate accounts that violate these Terms or compromise the integrity of the platform.
        </p>

        <h2>3. Acceptable Use</h2>
        <p>
          You agree to use YHatch only for lawful purposes and in compliance with all applicable laws and regulations. You may not:
        </p>
        <ul>
          <li>Post or distribute content that is harmful, discriminatory, unlawful, or misleading</li>
          <li>Access or use another user's account without permission</li>
          <li>Attempt to gain unauthorized access to our systems or data</li>
          <li>Engage in any behavior that disrupts or degrades the platform’s performance or experience for other users</li>
        </ul>

        <h2>4. Intellectual Property</h2>
        <p>
          All content, materials, designs, trademarks, logos, and software on the YHatch platform are the exclusive property of YHatch or its licensors. You may not copy, modify, distribute, or reverse-engineer any part of the platform without express written permission.
        </p>

        <h2>5. User Content</h2>
        <p>
          You retain ownership of any content you submit to YHatch, including application materials and project details. By submitting content, you grant YHatch a non-exclusive, royalty-free, worldwide license to use, store, reproduce, and display such content for the purposes of program evaluation, mentorship, platform operations, and community engagement.
        </p>
        <p>
          You affirm that your content does not infringe upon the intellectual property or privacy rights of any third party.
        </p>

        <h2>6. Privacy</h2>
        <p>
          Our collection and use of personal information is governed by our <a href="/privacy">Privacy Policy</a>. By using YHatch, you consent to the practices described therein, including the use of cookies and other tracking technologies.
        </p>

        <h2>7. Modifications to Services</h2>
        <p>
          We reserve the right to modify, suspend, or discontinue any aspect of YHatch at any time without notice. While we aim to provide uninterrupted service, we do not guarantee ongoing availability or access.
        </p>

        <h2>8. Disclaimers and Limitation of Liability</h2>
        <p>
          YHatch is provided on an "as is" and "as available" basis. We disclaim all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.
        </p>
        <p>
          To the fullest extent permitted by law, YHatch shall not be liable for any direct, indirect, incidental, consequential, or punitive damages resulting from your access to or use of the platform, including loss of data, business interruption, or unauthorized access to your information.
        </p>

        <h2>9. Termination</h2>
        <p>
          YHatch reserves the right to suspend or terminate your access to the platform at any time, with or without cause. Upon termination, your right to use the services will immediately cease. Provisions of these Terms that by their nature should survive termination shall do so.
        </p>

        <h2>10. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which YHatch operates. Any disputes arising under these Terms will be subject to the exclusive jurisdiction of the courts located in that jurisdiction.
        </p>

        <h2>11. Changes to These Terms</h2>
        <p>
          We may revise these Terms from time to time. Changes will be posted on this page with an updated effective date. Continued use of the platform after changes have been posted constitutes your acceptance of the revised Terms.
        </p>

        <h2>12. Contact Us</h2>
        <p>
          For any questions or concerns about these Terms of Service, please contact us at:
        </p>
        <p>
          <strong>Email:</strong> <a href="mailto:support@yhatch.com">support@yhatch.com</a>
        </p>

        <h2>Effective Date</h2>
        <p>These Terms were last updated on March 31, 2025.</p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfService;
