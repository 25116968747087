// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCx3cB5HpyCe8UUaYr5lEcucIbG-blQimg",
    authDomain: "yhatch1.firebaseapp.com",
    projectId: "yhatch1",
    storageBucket: "yhatch1.firebasestorage.app",
    messagingSenderId: "850726054427",
    appId: "1:850726054427:web:c913f80543c249439d26be"
  };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;
