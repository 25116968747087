// src/components/Contact.js
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './StaticPage.css';
import Footer from './Footer';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      await emailjs.send(
        'service_zyktl37',
        'template_rphphdl',
        {
          to_email: 'lilian@mxk8us.com',
          from_name: formData.name,
          from_email: formData.email,
          message: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`
        },
        'P3g42M8A8_DiQLcDf'
      );

      setSubmissionStatus('success');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Email error:', error);
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="static-page-wrapper">
      <div className="static-page">
        <h1 className="page-title">Contact YHatch</h1>
        <p className="intro-text">
          Get in touch with us! Send your questions, feedback, or support requests using the form below.
        </p>

        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="6"
              required
            />
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>

          {submissionStatus === 'success' && (
            <div className="status-message success">
              Message sent successfully! We'll respond as soon as possible.
            </div>
          )}

          {submissionStatus === 'error' && (
            <div className="status-message error">
              Error sending message. Please try again later.
            </div>
          )}
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;