// src/components/admin/AdminApplicationDetails.js
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { firestore } from "../../firebase";
import "./AdminStyles.css";
//import "./AdminApplicationDetails.css";

function AdminApplicationDetails() {
  const { id } = useParams();
  const history = useHistory();
  const [appData, setAppData] = useState(null);
  const [status, setStatus] = useState("");
  const [rating, setRating] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const unsubscribe = firestore
      .collection("applications")
      .doc(id)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            setAppData(data);
            setStatus(data.status || "In Progress");
            setRating(data.rating !== undefined ? data.rating : "");
          } else {
            alert("Application not found.");
            history.push("/admin/applications");
          }
        },
        (error) => {
          console.error("Error fetching application details:", error);
          alert("Error fetching application details: " + error.message);
        }
      );
    return () => unsubscribe();
  }, [id, history]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await firestore.collection("applications").doc(id).update({
        status,
        rating: Number(rating),
        updatedAt: new Date()
      });
      setMessage("Application updated successfully!");
    } catch (error) {
      console.error("Error updating application:", error);
      setMessage("Error updating application: " + error.message);
    }
  };

  if (!appData) return <p>Loading application details...</p>;

  const formatAddress = (address) => {
    if (!address) return "N/A";
    return `${address.line1}${address.line2 ? `, ${address.line2}` : ""}, ${address.city}, ${address.state} ${address.zip}, ${address.country}`;
  };

  const { 
    projectTitle,
    updatedAt,
    founders = [],
    companyInfo = {},
    progress = {},
    idea = {}
  } = appData;

  return (
    <div className="admin-application-details-container">
      <h2>Application Overview</h2>

      {/* Metadata */}
      <div className="metadata-section">
        <div className="info-row">
          <label>Application ID:</label>
          <span>{id}</span>
        </div>
        <div className="info-row">
          <label>Project Title:</label>
          <span>{projectTitle || "N/A"}</span>
        </div>
        <div className="info-row">
          <label>Last Updated:</label>
          <span>
            {updatedAt ? new Date(updatedAt.seconds * 1000).toLocaleString() : "N/A"}
          </span>
        </div>
      </div>

      {/* Team Members */}
      <div className="section">
        <h3>Team Members ({founders.length})</h3>
        {founders.map((founder, index) => (
          <div key={index} className="founder-card">
            <h4>{founder.firstName} {founder.lastName}</h4>
            <div className="info-grid">
              <div className="info-row">
                <label>Role:</label>
                <span>{founder.role || "N/A"}</span>
              </div>
              <div className="info-row">
                <label>Contact:</label>
                <span>{founder.phone} | Age: {founder.age}</span>
              </div>
              {founder.isMinor && (
                <>
                  <div className="info-row">
                    <label>Guardian:</label>
                    <span>{founder.guardianName} ({founder.guardianPhone})</span>
                  </div>
                  <div className="info-row">
                    <label>Guardian Address:</label>
                    <span>{founder.guardianAddress}</span>
                  </div>
                </>
              )}
              <div className="info-row">
                <label>Address:</label>
                <span>{formatAddress(founder.address)}</span>
              </div>
              <div className="info-row">
                <label>Education:</label>
                <span>
                  {(founder.education || []).map((edu, i) => (
                    <div key={i} className="education-item">
                      {edu.schoolName} - {edu.degree} ({edu.from} to {edu.to})
                    </div>
                  ))}
                </span>
              </div>
              <div className="info-row">
                <label>Student Status:</label>
                <span>{founder.inSchool || "N/A"}</span>
              </div>
              <div className="info-row">
                <label>Social Media:</label>
                <span>
                  {Object.entries(founder.socialMedia || {}).map(([platform, url]) => (
                    url && <div key={platform}>{platform}: {url}</div>
                  ))}
                </span>
              </div>
              <div className="info-row">
                <label>Accomplishments:</label>
                <span>{founder.accomplishments || "N/A"}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Project Details */}
      <div className="section">
        <h3>Project Details</h3>
        <div className="info-grid">
          <div className="info-row">
            <label>Project Name:</label>
            <span>{companyInfo.companyName || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Short Description:</label>
            <span>{companyInfo.shortDescription || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Website:</label>
            <span>{companyInfo.companyUrl || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Detailed Description:</label>
            <span>{companyInfo.description || "N/A"}</span>
          </div>
        </div>
      </div>

      {/* Development Progress */}
      <div className="section">
        <h3>Development Progress</h3>
        <div className="info-grid">
          <div className="info-row">
            <label>Progress Details:</label>
            <span>{progress.progressDetails || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Development Duration:</label>
            <span>{progress.duration || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Tech Stack:</label>
            <span>{progress.techStack || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>User Testing:</label>
            <span>{progress.userAdoption || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Revenue Status:</label>
            <span>{progress.revenueStatus || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Additional Notes:</label>
            <span>{progress.additionalContext || "N/A"}</span>
          </div>
        </div>
      </div>

      {/* Business Idea */}
      <div className="section">
        <h3>Business Concept</h3>
        <div className="info-grid">
          <div className="info-row">
            <label>Core Idea:</label>
            <span>{idea.ideaDetails || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Competitors:</label>
            <span>{idea.competitors || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Revenue Model:</label>
            <span>{idea.revenueModel || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Product Category:</label>
            <span>{idea.category || "N/A"}</span>
          </div>
          <div className="info-row">
            <label>Additional Ideas:</label>
            <span>{idea.otherIdeas || "N/A"}</span>
          </div>
        </div>
      </div>

      {/* Admin Controls */}
      <form onSubmit={handleUpdate} className="admin-controls">
        <h3>Application Management</h3>
        <div className="control-grid">
          <div className="control-row">
            <label>Status:</label>
            <select value={status} onChange={(e) => setStatus(e.target.value)} required>
              <option value="In Progress">In Progress</option>
              <option value="Submitted">Submitted</option>
              <option value="Incomplete">Incomplete</option>
              <option value="Needs More Information">Needs More Info</option>
            </select>
          </div>
          <div className="control-row">
            <label>Rating (1-10):</label>
            <input
              type="number"
              min="1"
              max="10"
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            />
          </div>
          <div className="control-row">
            <button type="submit">Update Application</button>
          </div>
        </div>
      </form>

      {message && <div className="status-message">{message}</div>}

      <button 
        onClick={() => history.push("/admin/applications")} 
        className="back-button"
      >
        Return to Applications List
      </button>
    </div>
  );
}

export default AdminApplicationDetails;