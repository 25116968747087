// src/components/UsefulLinks.js
import React from "react";
import "./StaticPage.css";
import Footer from "./Footer";

function UsefulLinks() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page">
        <h1>🔗 Useful Links for Young Founders</h1>
        <p>
          Whether you’re dreaming up your next big idea or already building your startup, these handpicked resources are here to fuel your journey! 🚀
        </p>

        <h2>📚 Learn & Level Up</h2>
        <ul>
          <li>
            <a href="https://www.startupschool.org" target="_blank" rel="noopener noreferrer">
              Y Combinator's Startup School
            </a>{" "}
            – Free startup curriculum and mentorship from YC 🧠
          </li>
          <li>
            <a href="https://www.khanacademy.org/college-careers-more" target="_blank" rel="noopener noreferrer">
              Khan Academy – Careers & Personal Finance
            </a>{" "}
            – Get smart about business and money basics 💸
          </li>
          <li>
            <a href="https://foundr.com" target="_blank" rel="noopener noreferrer">
              Foundr
            </a>{" "}
            – Bite-sized startup lessons and interviews with top entrepreneurs 🎙️
          </li>
          <li>
            <a href="https://learn.buildspace.so" target="_blank" rel="noopener noreferrer">
              Buildspace
            </a>{" "}
            – Learn to build real web3 projects with a community of young hackers 🛠️
          </li>
        </ul>

        <h2>🌍 Startup News & Trends</h2>
        <ul>
          <li>
            <a href="https://www.techcrunch.com" target="_blank" rel="noopener noreferrer">
              TechCrunch
            </a>{" "}
            – Stay up to date on the startup world 🌐
          </li>
          <li>
            <a href="https://www.inc.com" target="_blank" rel="noopener noreferrer">
              Inc.com
            </a>{" "}
            – Advice for entrepreneurs and growth strategies 📈
          </li>
          <li>
            <a href="https://www.entrepreneur.com" target="_blank" rel="noopener noreferrer">
              Entrepreneur
            </a>{" "}
            – Articles, tools, and guides for aspiring founders 👩‍💻
          </li>
          <li>
            <a href="https://www.fastcompany.com" target="_blank" rel="noopener noreferrer">
              Fast Company
            </a>{" "}
            – Innovation, design, and creativity in startups ✨
          </li>
        </ul>

        <h2>🛠️ Tools & Communities</h2>
        <ul>
          <li>
            <a href="https://www.notion.so/startups" target="_blank" rel="noopener noreferrer">
              Notion for Startups
            </a>{" "}
            – Organize your startup like a pro 📋
          </li>
          <li>
            <a href="https://www.figma.com" target="_blank" rel="noopener noreferrer">
              Figma
            </a>{" "}
            – Design sleek user interfaces, no experience required 🎨
          </li>
          <li>
            <a href="https://glitch.com" target="_blank" rel="noopener noreferrer">
              Glitch
            </a>{" "}
            – Create web apps and experiments live with code 💻
          </li>
          <li>
            <a href="https://www.producthunt.com" target="_blank" rel="noopener noreferrer">
              Product Hunt
            </a>{" "}
            – Discover and launch new products 🚀
          </li>
          <li>
            <a href="https://dev.to" target="_blank" rel="noopener noreferrer">
              DEV Community
            </a>{" "}
            – A welcoming community for coders and makers 🧑‍💻
          </li>
        </ul>

        <h2>💬 Need Help?</h2>
        <p>
          If you’re feeling stuck or unsure, <strong>reach out to us anytime</strong>. YHatch is here to help you build something amazing.
        </p>
        <p>
          📧 Email: <a href="mailto:support@yhatch.com">support@yhatch.com</a>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default UsefulLinks;
