// src/components/ApplicationGuide.js
import React from 'react';
import { Link } from 'react-router-dom';
import './ApplicationGuide.css';

function ApplicationGuide() {
  return (
    <div className="application-form-container">
      <h2>Application Guide</h2>
      <div className="guide-content">
        <h3>Complete Guide to Perfect Application Submission</h3>

        {/* Team Members Section */}
        <section className="guide-section">
          <h4>👥 Step 1: Team Members</h4>
          <ul>
            <li>
              <strong>Project Name</strong>
              <div className="example">Ex: "CodeCraft - Teen Coding Platform"</div>
              <p>Your project's official name. Make it memorable and descriptive.</p>
            </li>

            <li>
              <strong>Team Member Details (per member)</strong>
              <ul className="nested-list">
                <li>
                  <strong>Full Name:</strong> Legal names with proper capitalization
                  <div className="example">Ex: "Emma Grace Johnson"</div>
                </li>
                <li>
                  <strong>Minor Status:</strong> Required for members under 18. Triggers guardian fields:
                  <div className="example">Guardian Phone: "(555) 123-4567"</div>
                </li>
                <li>
                  <strong>Contact Information:</strong>
                  <div className="example">Phone: "+1 (555) 123-4567"</div>
                  <div className="example">Age: "16" (Numbers only)</div>
                </li>
                <li>
                  <strong>Full Address:</strong> Complete mailing address
                  <div className="example">Line 1: "123 Innovation Blvd"</div>
                  <div className="example">Country: "United States" (Full country name)</div>
                </li>
                <li>
                  <strong>Role Description:</strong> Specific contribution details
                  <div className="example">Ex: "Frontend Developer & UX Designer"</div>
                </li>
                <li>
                  <strong>Education History:</strong> Add all relevant education entries
                  <div className="example">School: "Tech Valley STEM High School"</div>
                  <div className="example">Degree: "High School Diploma (Expected 2025)"</div>
                </li>
                <li>
                  <strong>Social Media:</strong> Professional/academic profiles only
                  <div className="example">LinkedIn: "linkedin.com/in/emma-johnson-dev"</div>
                </li>
                <li>
                  <strong>Accomplishments:</strong> Specific achievements with metrics
                  <div className="example">"Built a Python-based attendance system adopted by my school, serving 1,200+ students"</div>
                </li>
              </ul>
            </li>
          </ul>
        </section>

        {/* Project Details Section */}
        <section className="guide-section">
          <h4>🚀 Step 2: Project Details</h4>
          <ul>
            <li>
              <strong>Company/Project Name</strong>
              <div className="example">Ex: "EduPlay Innovations"</div>
              <p>Legal name if registered, or working project name</p>
            </li>
            <li>
              <strong>Brief Description</strong>
              <div className="example">"Mobile app that teaches coding through AR puzzles"</div>
              <p>Think elevator pitch - concise and exciting</p>
            </li>
            <li>
              <strong>Detailed Description</strong>
              <div className="example">"Our app combines... [Technical details]... Users interact by... [Usage flow]..."</div>
              <p>Include both technical implementation and user experience details</p>
            </li>
            <li>
              <strong>Website URL</strong>
              <div className="example">"https://prototype.eduplay.app" (Even if WIP)</div>
              <p>GitHub repos accepted if no website exists</p>
            </li>
          </ul>
        </section>

        {/* Current Progress Section */}
        <section className="guide-section">
          <h4>📈 Step 3: Current Progress</h4>
          <ul>
            <li>
              <strong>Progress Details</strong>
              <div className="example">"Working MVP with 3 core features implemented"</div>
              <p>Include development stage and validation milestones</p>
            </li>
            <li>
              <strong>Development Duration</strong>
              <div className="example">"6 months (Started January 2023)"</div>
            </li>
            <li>
              <strong>Tech Stack</strong>
              <div className="example">"React Native, Python Flask, AWS Amplify"</div>
              <p>List all major technologies used</p>
            </li>
            <li>
              <strong>User Testing</strong>
              <div className="example">"50+ beta testers from 3 high schools"</div>
            </li>
            <li>
              <strong>Revenue Status</strong>
              <div className="example">"Pre-revenue" or "$500 from pilot program"</div>
            </li>
            <li>
              <strong>Additional Context</strong>
              <div className="example">"Won school innovation grant of $2,000 in March 2023"</div>
            </li>
          </ul>
        </section>

        {/* Idea Overview Section */}
        <section className="guide-section">
          <h4>💡 Step 4: Idea Overview</h4>
          <ul>
            <li>
              <strong>Inspiration</strong>
              <div className="example">"My younger sister struggled with math concepts, so I wanted to make learning tactile"</div>
            </li>
            <li>
              <strong>Competitive Analysis</strong>
              <div className="example">"Similar to Duolingo but focused on STEM subjects for visual learners"</div>
            </li>
            <li>
              <strong>Revenue Model</strong>
              <div className="example">"Freemium model with school district licensing"</div>
            </li>
            <li>
              <strong>Project Category</strong>
              <div className="example">"EdTech Gaming Platform"</div>
            </li>
            <li>
              <strong>Additional Ideas</strong>
              <div className="example">"VR chemistry lab simulation concept in development"</div>
            </li>
          </ul>
        </section>

        <div className="pro-tips">
          <h4>💎 Pro Tips</h4>
          <ul>
            <li>Save team members individually before progressing</li>
            <li>Use specific metrics: "Improved user retention by 40%" vs "Made it better"</li>
            <li>For technical fields: "Used TensorFlow for ML model training"</li>
            <li>Update drafts regularly using 'Save & Exit'</li>
          </ul>
        </div>

        <div className="navigation-links">
          <Link to="/application/new" className="return-link">
            ← Back to Application Form
          </Link>
          <Link to="/home" className="return-link">
            ← Back to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ApplicationGuide;