// src/components/Investors.js
import React from "react";
import "./StaticPage.css";
import Footer from "./Footer";

function Investors() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page investors-page">
        <h1>🤝 Partner with the Next Generation</h1>
        <p>
          At <strong>YHatch</strong>, we believe in the power of youth to reshape the future. 
          We're building the go-to platform for ambitious, curious, and impact-driven teen founders — and we're looking for investors who share that vision.
        </p>

        <section className="investor-section">
          <h2>🌱 Why Invest in Youth Innovation?</h2>
          <ul>
            <li>
              <strong>Early-Stage Potential:</strong> Young founders bring bold ideas, fresh perspectives, and limitless energy.
            </li>
            <li>
              <strong>Underserved Market:</strong> Youth-focused innovation is often overlooked — your support fills a critical gap.
            </li>
            <li>
              <strong>Long-Term Impact:</strong> Shape tomorrow’s leading entrepreneurs and industries from the very beginning.
            </li>
          </ul>
        </section>

        <section className="investor-section">
          <h2>🚀 What We Offer Investors</h2>
          <ul>
            <li>
              Access to a curated pipeline of high-potential youth-led startups.
            </li>
            <li>
              Opportunities to mentor, judge demo days, or participate in office hours.
            </li>
            <li>
              Brand alignment with purpose-driven innovation and next-gen leadership.
            </li>
            <li>
              Quarterly updates on portfolio progress and alumni ventures.
            </li>
          </ul>
        </section>

        <section className="investor-section">
          <h2>💬 Voices from Our Partners</h2>
          <blockquote>
            “YHatch is the bridge between untapped youth talent and real-world opportunity.
            Supporting these founders is not just the right thing to do — it's smart investing.”
          </blockquote>
          <p>– Angel Investor & YHatch Mentor</p>
        </section>

        <section className="investor-section">
          <h2>📩 Let’s Talk</h2>
          <p>
            Interested in investing in the next generation of changemakers? Whether you're a VC, 
            angel investor, donor, or operator who wants to give back — we’d love to connect.
          </p>
          <p>
            Reach out to our team at{" "}
            <a href="mailto:invest@yhatch.com">invest@yhatch.com</a> and we’ll follow up with 
            more information, upcoming events, and collaboration opportunities.
          </p>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Investors;
