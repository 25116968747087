// src/components/CookiePolicy.js
import React from "react";
import "./StaticPage.css";
import Footer from "./Footer";

function CookiePolicy() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page">
        <h1>Cookie Policy</h1>
        <p><strong>Effective Date:</strong> January 1, 2025</p>
        <p>
          This Cookie Policy explains how YHatch ("we", "us", or "our") uses cookies and similar technologies on our website (the "Site"). It provides detailed information about what cookies are, why we use them, and how you can manage their use. By accessing or using our Site, you consent to our use of cookies in accordance with this policy.
        </p>
        
        <h2>1. What Are Cookies?</h2>
        <p>
          Cookies are small text files that are stored on your device (computer, mobile phone, tablet) when you visit a website. They are used to improve your browsing experience by allowing the website to remember your actions and preferences over time.
        </p>
        
        <h2>2. Why Do We Use Cookies?</h2>
        <p>
          We use cookies to:
        </p>
        <ul>
          <li>
            <strong>Enhance User Experience:</strong> Ensure the website functions efficiently and remembers your preferences.
          </li>
          <li>
            <strong>Analyze Website Traffic:</strong> Collect anonymous data to help us understand how our Site is used and how we can improve it.
          </li>
          <li>
            <strong>Personalize Content and Advertising:</strong> Provide you with content and ads that are more relevant to your interests.
          </li>
        </ul>
        
        <h2>3. Types of Cookies We Use</h2>
        <h3>a. Essential Cookies</h3>
        <p>
          These cookies are necessary for the basic functioning of our Site. They enable core functionalities such as security, network management, and accessibility. Without these cookies, services you have requested, like secure logins, cannot be provided.
        </p>
        
        <h3>b. Performance Cookies</h3>
        <p>
          Performance cookies help us analyze how visitors use our Site by collecting information about which pages are visited most often and any error messages received. This data assists us in improving our Site’s performance.
        </p>
        
        <h3>c. Functionality Cookies</h3>
        <p>
          Functionality cookies allow our Site to remember choices you make (such as your username, language, or region) and provide enhanced, personalized features. These cookies may also be used to provide services you have asked for.
        </p>
        
        <h3>d. Advertising/Targeting Cookies</h3>
        <p>
          These cookies are used to deliver advertisements that are relevant to you and your interests. They may also limit the number of times you see an ad and help measure the effectiveness of advertising campaigns.
        </p>
        
        <h2>4. Managing and Controlling Cookies</h2>
        <p>
          Most web browsers allow you to control cookies through their settings. You can set your browser to refuse cookies or alert you when cookies are being sent. However, if you disable cookies, some parts of our Site may not function properly.
        </p>
        <p>
          For more detailed instructions on managing cookies, please refer to your browser’s help documentation.
        </p>
        
        <h2>5. Changes to This Cookie Policy</h2>
        <p>
          We may update this Cookie Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy regularly to stay informed about our use of cookies.
        </p>
        
        <h2>6. Contact Us</h2>
        <p>
          If you have any questions or concerns about our use of cookies or this Cookie Policy, please contact us at <a href="mailto:support@yhatch.com">support@yhatch.com</a>.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default CookiePolicy;
