// src/App.js
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Homepage from "./components/Homepage";
import Login from "./components/Login";
import Signup from "./components/Signup";

import Home from "./components/Home"; // Dashboard page after login
import Profile from "./components/Profile";
import MyApplications from "./components/MyApplications";
import ApplicationForm from "./components/ApplicationForm";
import ProjectConceptForm from "./components/ProjectConceptForm";
import ApplicationGuide from './components/ApplicationGuide';
import AdminApplicationDetails from "./components/admin/AdminApplicationDetails";
import AdminConceptDetails from "./components/admin/AdminConceptDetails";

import About from "./components/About";
import Contact from "./components/Contact";
import Investors from "./components/Investors";
import Press from "./components/Press";

import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Disclaimers from "./components/Disclaimers";
import CookiePolicy from "./components/CookiePolicy";
import FAQ from "./components/FAQ";
import UsefulLinks from "./components/UsefulLinks";

import AdminDashboard from "./components/admin/AdminDashboard";

import "./App.css";

function App() {
  return (
    <Switch>
      {/* Public Pages */}
      <Route exact path="/" component={Homepage} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      {/* Authenticated User Pages */}
      <Route path="/home" component={Home} />
      <Route path="/profile" component={Profile} />
      <Route path="/applications" component={MyApplications} />
      <Route path="/application-guide" component={ApplicationGuide} />
      <Route path="/application/new" component={ApplicationForm} />
      <Route path="/application/edit/:id" component={ApplicationForm} />
      <Route path="/submit-concept/:conceptId?" component={ProjectConceptForm} />

      {/* Admin Dashboard */}
      <Route path="/admin/application/:id" component={AdminApplicationDetails} />
      <Route path="/admin/concept/:id" component={AdminConceptDetails} />
      <Route path="/admin" component={AdminDashboard} />

      {/* Static Pages */}
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path="/investors" component={Investors} />
      <Route path="/press" component={Press} />
      <Route path="/privacy" component={PrivacyPolicy} />
      <Route path="/terms" component={TermsOfService} />
      <Route path="/disclaimers" component={Disclaimers} />
      <Route path="/cookie" component={CookiePolicy} />
      <Route path="/faq" component={FAQ} />
      <Route path="/links" component={UsefulLinks} />
      {/* Fallback */}
      <Redirect to="/" />
    </Switch>
  );
}

export default App;
