// src/components/Press.js
import React from "react";
import "./StaticPage.css";
import Footer from "./Footer";

function Press() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page press-page">
        <h1>Press & Media</h1>

        <p className="intro-text">
          YHatch is a new initiative focused on empowering young innovators to develop meaningful projects 
          and entrepreneurial skills. We welcome media inquiries and partnership opportunities.
        </p>

        <section className="press-section">
          <h2>Media Resources</h2>
          <p>Available upon request:</p>
          <ul>
            <li>Organization background and mission statement</li>
            <li>Founding team bios and headshots</li>
            <li>Brand guidelines and logo package</li>
          </ul>
          <p>
            Please contact us at <a href="mailto:press@yhatch.com">press@yhatch.com</a> 
            for any media resource requests.
          </p>
        </section>

        <section className="press-section">
          <h2>Contact Information</h2>
          <div className="contact-info">
            <p>
              <strong>Media Inquiries:</strong><br />
              <a href="mailto:press@yhatch.com">press@yhatch.com</a>
            </p>
            <p>
              <strong>General Support:</strong><br />
              <a href="mailto:support@yhatch.com">support@yhatch.com</a>
            </p>
          </div>
        </section>

        <section className="press-section">
          <h2>Stay Connected</h2>
          <p>
            Follow our journey and be the first to hear about our initiatives:
          </p>
          <ul className="social-links">
            <li>Instagram: <a href="https://instagram.com/yhatch">@yhatch</a></li>
            <li>Website: <a href="https://yhatch.com">www.yhatch.com</a></li>
          </ul>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Press;