// src/components/About.js
import React from 'react';
import './StaticPage.css';
import Footer from './Footer';

function About() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page">
        <h1>🚀 About YHatch</h1>

        <p>
          Welcome to <strong>YHatch</strong> — the launchpad for the next generation of bold, creative, and unstoppable youth entrepreneurs.
        </p>

        <p>
          We’re not just an accelerator. We’re a movement. A community. A safe space where high schoolers, teens, and young adults can turn their dreams into companies, their pitches into products, and their ideas into impact.
        </p>

        <h2>🌟 Our Mission</h2>
        <p>
          At YHatch, we believe youth are not just the leaders of tomorrow — they are the innovators of today. Our mission is to:
        </p>
        <ul>
          <li>Empower young founders with the tools and confidence to build real startups</li>
          <li>Create a community where ideas are celebrated, not judged</li>
          <li>Democratize access to mentorship, funding, and business know-how</li>
          <li>Help youth find their voice, their team, and their vision</li>
        </ul>

        <h2>🔧 What We Offer</h2>
        <p>
          YHatch is packed with support and structure to help you hatch your idea and help it take flight:
        </p>
        <ul>
          <li><strong>Startup Curriculum:</strong> Learn by doing with startup-building modules built just for youth</li>
          <li><strong>Mentorship:</strong> Connect with founders, investors, and creators who’ve been in your shoes</li>
          <li><strong>Pitch Coaching:</strong> Practice your story, get feedback, and prepare to wow the world</li>
          <li><strong>Demo Days:</strong> Showcase your venture to a supportive audience (and potential backers!)</li>
          <li><strong>Community:</strong> Join a crew of fellow teen founders who get you and your grind</li>
        </ul>

        <h2>🎯 Who Is YHatch For?</h2>
        <p>
          If you're a curious teen with an idea that won’t stop buzzing in your head — YHatch is for you. Whether you’ve built a prototype, launched a TikTok page, coded your first app, or just love solving problems — we want to meet you.
        </p>

        <h2>🌈 Our Vibe</h2>
        <p>
          We’re not here for gatekeeping or boring business jargon. YHatch is inclusive, high-energy, experiment-friendly, and proudly youth-led. We believe failure is part of the process and that every "why not?" is a future unicorn waiting to be built.
        </p>

        <h2>💬 Hear from Founders</h2>
        <p>
          “YHatch was the first place I felt like my age didn’t limit my ambition. It gave me a space to build, ask questions, and be taken seriously.” — <em>Alex, 17, Founder of EcoCart</em>
        </p>

        <h2>📣 Join the Movement</h2>
        <p>
          Whether you’re dreaming up your first venture or already running your own online brand, we’re here to cheer you on, guide you forward, and give you the resources to level up.
        </p>

        <p>
          Let’s build what’s next — together. 💡
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default About;
