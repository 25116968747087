// src/components/Disclaimers.js
import React from "react";
import "./StaticPage.css";
import Footer from "./Footer";

function Disclaimers() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page">
        <h1>Disclaimers</h1>

        <p>
          The information provided by <strong>YHatch</strong> ("we", "us", or "our") on this website and through our services is intended solely for general informational and educational purposes.
          While we strive to ensure the accuracy and relevance of the content we publish, we make no guarantees or warranties, express or implied, regarding the completeness, accuracy, reliability, suitability, or availability of any information contained on the site or provided through our programs.
        </p>

        <h2>Not Professional Advice</h2>
        <p>
          The content shared through YHatch does not constitute legal, financial, business, investment, or other professional advice. Any decisions you make based on the content are made at your own risk.
          We strongly recommend consulting with qualified professionals before acting on any information obtained from our website, resources, or community.
        </p>

        <h2>Third-Party Content and Links</h2>
        <p>
          Our website may contain links to external websites or content belonging to third parties. These links are provided for convenience only. We do not control or endorse the content of third-party websites and are not responsible for any information, services, or materials found there.
        </p>

        <h2>User-Generated Content</h2>
        <p>
          YHatch may include content submitted by users, such as community posts, project details, or startup pitches. We do not take responsibility for the accuracy, reliability, or legality of any user-generated content, nor do we endorse any views expressed therein.
        </p>

        <h2>No Guarantees</h2>
        <p>
          Participation in YHatch programs, mentorships, or startup support initiatives does not guarantee success, funding, business outcomes, or partnership opportunities.
          Every entrepreneurial journey is unique, and outcomes depend on numerous factors outside of our control.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          Under no circumstances shall YHatch or its affiliates be held liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from your use of, or reliance on, any content or services provided.
        </p>

        <h2>Changes to This Disclaimer</h2>
        <p>
          We reserve the right to update or modify this disclaimer at any time without prior notice. Your continued use of the website after any changes constitutes your acknowledgment and agreement to the updated terms.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this disclaimer or any content on our site, please contact us at{" "}
          <a href="mailto:support@yhatch.com">support@yhatch.com</a>.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Disclaimers;
