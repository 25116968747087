// src/components/ApplicationForm.js
import React, { useState, useEffect } from 'react';
import './ApplicationForm.css';
import { firestore, auth } from '../firebase';
import { serverTimestamp } from 'firebase/firestore';
import DashboardLayout from './DashboardLayout';
import { useHistory, useLocation } from 'react-router-dom';

function ApplicationForm({ editApplication = false, applicationData = null }) {
  const history = useHistory();
  const location = useLocation(); // Get navigation state

 // Updated defaultFormData - remove unused fields and match form structure
const defaultFormData = {
    projectTitle: '',
    founders: [],
    companyInfo: {
      companyName: '',
      shortDescription: '',
      companyUrl: '',
      description: '',
    },
    progress: {
      progressDetails: '',
      duration: '',
      techStack: '',
      userAdoption: '',
      revenueStatus: '',
      additionalContext: ''
    },
    idea: {
      ideaDetails: '',
      competitors: '',
      revenueModel: '',
      category: '',
      otherIdeas: ''
    },
    status: 'In Progress',
    userId: auth.currentUser ? auth.currentUser.uid : null
  };

  const [currentStep, setCurrentStep] = useState(1);
  // Initialize form data from location state or defaults
  const [formData, setFormData] = useState(
    location.state?.applicationData || defaultFormData
  );
  const [userData, setUserData] = useState(null);

  // Update formData if applicationData prop changes (e.g., when editing)
  useEffect(() => {
    if (editApplication && applicationData) {
      setFormData({ ...applicationData, id: applicationData.id });
    }    
  }, [editApplication, applicationData]);

  // Fetch current user data
  useEffect(() => {
    const fetchUser = async () => {
      const user = auth.currentUser;
      if (user) {
        const doc = await firestore.collection('users').doc(user.uid).get();
        if (doc.exists) {
          setUserData(doc.data());
        }
      }
    };
    fetchUser();
  }, []);

  // Logout function for DashboardLayout
  const handleLogout = async () => {
    await auth.signOut();
    history.push('/');
  };

  // Basic state updaters
  const handleChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

// Founder handling - updated to match actual form fields
const addFounder = () => {
    setFormData(prev => ({
      ...prev,
      founders: [
        ...prev.founders,
        {
          firstName: '',
          middleName: '',
          lastName: '',
          isMinor: false,
          guardianName: '',
          guardianPhone: '',
          guardianAddress: '',
          phone: '',
          age: '',
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: '',
            zip: ''
          },
          role: '',
          inSchool: '',
          linkedin: '',
          education: [],
          socialMedia: {},
          accomplishments: '',
          saved: false
        }
      ]
    }));
  };

  const updateFounder = (index, field, value) => {
    const updatedFounders = [...formData.founders];
    updatedFounders[index][field] = value;
    setFormData(prev => ({
      ...prev,
      founders: updatedFounders
    }));
  };

  const saveFounder = (index) => {
    const updatedFounders = [...formData.founders];
    updatedFounders[index].saved = true;
    setFormData(prev => ({
      ...prev,
      founders: updatedFounders
    }));
  };

  const editFounder = (index) => {
    const updatedFounders = [...formData.founders];
    updatedFounders[index].saved = false;
    setFormData(prev => ({
      ...prev,
      founders: updatedFounders
    }));
  };

  const removeFounder = (index) => {
    const updatedFounders = formData.founders.filter((_, i) => i !== index);
    setFormData(prev => ({
      ...prev,
      founders: updatedFounders
    }));
  };

  // Education handling for founders
  const addEducation = (founderIndex) => {
    const updatedFounders = [...formData.founders];
    if (!updatedFounders[founderIndex].education) {
      updatedFounders[founderIndex].education = [];
    }
    updatedFounders[founderIndex].education.push({
      schoolName: '',
      degree: '',
      fieldOfStudy: '',
      from: '',
      to: ''
    });
    setFormData(prev => ({
      ...prev,
      founders: updatedFounders
    }));
  };

  const removeEducation = (founderIndex, eduIndex) => {
    const updatedFounders = [...formData.founders];
    updatedFounders[founderIndex].education = updatedFounders[founderIndex].education.filter((_, i) => i !== eduIndex);
    setFormData(prev => ({
      ...prev,
      founders: updatedFounders
    }));
  };

  // Final submission of the application (final submit)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSave = { ...formData };
  
      if (dataToSave.companyInfo.demo instanceof File) {
        dataToSave.companyInfo.demo = '';
      }
  
      dataToSave.updatedAt = serverTimestamp();
  
      if (formData.id) {
        await firestore.collection('applications').doc(formData.id).update(dataToSave);
      } else {
        const docRef = await firestore.collection('applications').add({
          ...dataToSave,
          createdAt: serverTimestamp()
        });
        setFormData(prev => ({ ...prev, id: docRef.id }));
      }
      
  
      alert('Application submitted successfully!');
      history.push('/applications');
    } catch (error) {
      console.error('Error submitting application:', error);
      alert('Error submitting application: ' + error.message);
    }
  };
  

  // Save for Later: mark status as "Draft", save the application and redirect
  const handleSaveForLater = async () => {
    try {
      const dataToSave = { ...formData, status: 'Draft' };
  
      if (dataToSave.companyInfo.demo instanceof File) {
        dataToSave.companyInfo.demo = '';
      }
  
      dataToSave.updatedAt = serverTimestamp();
  
      if (formData.id) {
        await firestore.collection('applications').doc(formData.id).update(dataToSave);
      } else {
        const docRef = await firestore.collection('applications').add({
          ...dataToSave,
          createdAt: serverTimestamp()
        });
        setFormData(prev => ({ ...prev, id: docRef.id }));
      }
      
  
      alert('Application saved as draft!');
      history.push('/applications');
    } catch (error) {
      console.error('Error saving application:', error);
      alert('Error saving application: ' + error.message);
    }
  };
  

  // Tab Navigation (step labels)
  const steps = [
    'Team Members',
    'Project Details',
    'Current Progress',
    'Idea Overview',
  ];

  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, steps.length));
  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 1));
  const goToStep = (step) => setCurrentStep(step);

  return (
    <DashboardLayout onLogout={handleLogout} user={userData}>
      <div className="application-form-container">
        <div className="form-header">
          <h2>{editApplication ? 'Edit Your Application' : 'New Application'}</h2>
          <button className="save-exit-btn" onClick={handleSaveForLater}>
            Save & Exit
          </button>
        </div>

        {/* Tab Navigation */}
        <div className="tabs">
          {steps.map((label, idx) => {
            const stepNum = idx + 1;
            return (
              <button
                key={stepNum}
                className={`tab ${currentStep === stepNum ? 'active' : ''}`}
                onClick={() => goToStep(stepNum)}
              >
                {label}
              </button>
            );
          })}
        </div>

        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <div className="form-step">
              <h3>Step 1: Team Members</h3>
              <label>
                Project Name:
                <input
                  type="text"
                  value={formData.projectTitle}
                  onChange={(e) => handleInputChange('projectTitle', e.target.value)}
                  required
                />
              </label>
              <div className="founders-section">
                <h4>Team Member Information</h4>
                {formData.founders.map((founder, index) => (
                  <div key={index} className="founder-card">
                    {founder.saved ? (
                      <div className="saved-founder">
                        <p className="founder-name">
                          {founder.firstName} {founder.lastName}
                        </p>
                        <div className="founder-actions-inline">
                          <button type="button" onClick={() => editFounder(index)}>
                            Edit
                          </button>
                          <button type="button" onClick={() => removeFounder(index)}>
                            Delete
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <label>
                          First Name:
                          <input
                            type="text"
                            value={founder.firstName}
                            onChange={(e) => updateFounder(index, 'firstName', e.target.value)}
                            required
                          />
                        </label>
                        <label>
                          Middle Name:
                          <input
                            type="text"
                            value={founder.middleName}
                            onChange={(e) => updateFounder(index, 'middleName', e.target.value)}
                          />
                        </label>
                        <label>
                          Last Name:
                          <input
                            type="text"
                            value={founder.lastName}
                            onChange={(e) => updateFounder(index, 'lastName', e.target.value)}
                            required
                          />
                        </label>
                        <label className="inline-label">
                          Is the team member a minor?{" "}
                          <input
                            type="checkbox"
                            checked={founder.isMinor}
                            onChange={(e) => updateFounder(index, 'isMinor', e.target.checked)}
                          />
                        </label>
                        {founder.isMinor && (
                          <div className="minor-info">
                            <label>
                              Guardian Name:
                              <input
                                type="text"
                                value={founder.guardianName}
                                onChange={(e) => updateFounder(index, 'guardianName', e.target.value)}
                                required
                              />
                            </label>
                            <label>
                              Guardian Phone:
                              <input
                                type="text"
                                value={founder.guardianPhone}
                                onChange={(e) => updateFounder(index, 'guardianPhone', e.target.value)}
                                required
                              />
                            </label>
                            <label>
                              Guardian Address:
                              <input
                                type="text"
                                value={founder.guardianAddress}
                                onChange={(e) => updateFounder(index, 'guardianAddress', e.target.value)}
                                required
                              />
                            </label>
                          </div>
                        )}
                        <label>
                          Phone Number:
                          <input
                            type="text"
                            value={founder.phone}
                            onChange={(e) => updateFounder(index, 'phone', e.target.value)}
                            required
                          />
                        </label>
                        <label>
                          Age:
                          <input
                            type="number"
                            value={founder.age}
                            onChange={(e) => updateFounder(index, 'age', e.target.value)}
                            required
                          />
                        </label>
                        <div className="address-section">
                          <h5>Address:</h5>
                          <label>
                            Address Line 1:
                            <input
                              type="text"
                              value={founder.address.line1}
                              onChange={(e) => {
                                const updatedAddress = { ...founder.address, line1: e.target.value };
                                updateFounder(index, 'address', updatedAddress);
                              }}
                              required
                            />
                          </label>
                          <label>
                            Address Line 2:
                            <input
                              type="text"
                              value={founder.address.line2}
                              onChange={(e) => {
                                const updatedAddress = { ...founder.address, line2: e.target.value };
                                updateFounder(index, 'address', updatedAddress);
                              }}
                            />
                          </label>
                          <label>
                            City:
                            <input
                              type="text"
                              value={founder.address.city}
                              onChange={(e) => {
                                const updatedAddress = { ...founder.address, city: e.target.value };
                                updateFounder(index, 'address', updatedAddress);
                              }}
                              required
                            />
                          </label>
                          <label>
                            State:
                            <input
                              type="text"
                              value={founder.address.state}
                              onChange={(e) => {
                                const updatedAddress = { ...founder.address, state: e.target.value };
                                updateFounder(index, 'address', updatedAddress);
                              }}
                              required
                            />
                          </label>
                          <label>
                            Country:
                            <input
                              type="text"
                              value={founder.address.country}
                              onChange={(e) => {
                                const updatedAddress = { ...founder.address, country: e.target.value };
                                updateFounder(index, 'address', updatedAddress);
                              }}
                              required
                            />
                          </label>
                          <label>
                            Zip Code:
                            <input
                              type="text"
                              value={founder.address.zip}
                              onChange={(e) => {
                                const updatedAddress = { ...founder.address, zip: e.target.value };
                                updateFounder(index, 'address', updatedAddress);
                              }}
                              required
                            />
                          </label>
                        </div>
                        <label>
                        What's your role in the project? (Builder, designer, idea-machine, or something else entirely?!)
                          <input
                            type="text"
                            value={founder.role}
                            onChange={(e) => updateFounder(index, 'role', e.target.value)}
                            required
                          />
                        </label>
                        <label>
                          Are you currently a student?
                          <select
                            value={founder.inSchool}
                            onChange={(e) => updateFounder(index, 'inSchool', e.target.value)}
                            required
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </label>
                        <label>
                          LinkedIn Profile URL(optional):
                          <input
                            type="url"
                            value={founder.linkedin}
                            onChange={(e) => updateFounder(index, 'linkedin', e.target.value)}
                          />
                        </label>
                        <div className="education-section">
                          <h5>Education</h5>
                          {founder.education &&
                            founder.education.map((edu, eduIndex) => (
                              <div key={eduIndex} className="education-card">
                                <label>
                                  School Name:
                                  <input
                                    type="text"
                                    value={edu.schoolName}
                                    onChange={(e) => {
                                      const updatedEdu = [...founder.education];
                                      updatedEdu[eduIndex].schoolName = e.target.value;
                                      updateFounder(index, 'education', updatedEdu);
                                    }}
                                    required
                                  />
                                </label>
                                <label>
                                  Degree/Level:
                                  <input
                                    type="text"
                                    value={edu.degree}
                                    onChange={(e) => {
                                      const updatedEdu = [...founder.education];
                                      updatedEdu[eduIndex].degree = e.target.value;
                                      updateFounder(index, 'education', updatedEdu);
                                    }}
                                    required
                                  />
                                </label>
                                <label>
                                  Field of Study(Leave it blank for K-12):
                                  <input
                                    type="text"
                                    value={edu.fieldOfStudy}
                                    onChange={(e) => {
                                      const updatedEdu = [...founder.education];
                                      updatedEdu[eduIndex].fieldOfStudy = e.target.value;
                                      updateFounder(index, 'education', updatedEdu);
                                    }}
                                  />
                                </label>
                                <label>
                                  From:
                                  <input
                                    type="date"
                                    value={edu.from}
                                    onChange={(e) => {
                                      const updatedEdu = [...founder.education];
                                      updatedEdu[eduIndex].from = e.target.value;
                                      updateFounder(index, 'education', updatedEdu);
                                    }}
                                    required
                                  />
                                </label>
                                <label>
                                  To:
                                  <input
                                    type="date"
                                    value={edu.to}
                                    onChange={(e) => {
                                      const updatedEdu = [...founder.education];
                                      updatedEdu[eduIndex].to = e.target.value;
                                      updateFounder(index, 'education', updatedEdu);
                                    }}
                                    required
                                  />
                                </label>
                                <button
                                  type="button"
                                  className="delete-education-btn"
                                  onClick={() => removeEducation(index, eduIndex)}
                                >
                                  Delete Education
                                </button>
                              </div>
                            ))}
                          <button type="button" onClick={() => addEducation(index)}>
                            Add Education
                          </button>
                        </div>
                        <label>
                          Social Media (optional):
                          <input
                            type="text"
                            placeholder="Personal website"
                            onChange={(e) =>
                              updateFounder(index, 'socialMedia', {
                                ...founder.socialMedia,
                                website: e.target.value
                              })
                            }
                          />
                          <input
                            type="text"
                            placeholder="Instagram"
                            onChange={(e) =>
                              updateFounder(index, 'socialMedia', {
                                ...founder.socialMedia,
                                instagram: e.target.value
                              })
                            }
                          />
                          <input
                            type="text"
                            placeholder="TikTok"
                            onChange={(e) =>
                              updateFounder(index, 'socialMedia', {
                                ...founder.socialMedia,
                                tiktok: e.target.value
                              })
                            }
                          />
                          <input
                            type="text"
                            placeholder="Twitter"
                            onChange={(e) =>
                              updateFounder(index, 'socialMedia', {
                                ...founder.socialMedia,
                                twitter: e.target.value
                              })
                            }
                          />
                          <input
                            type="text"
                            placeholder="Github"
                            onChange={(e) =>
                              updateFounder(index, 'socialMedia', {
                                ...founder.socialMedia,
                                github: e.target.value
                              })
                            }
                          />
                          <input
                            type="text"
                            placeholder="Other"
                            onChange={(e) =>
                              updateFounder(index, 'socialMedia', {
                                ...founder.socialMedia,
                                other: e.target.value
                              })
                            }
                          />
                        </label>
                        <label>
                        Any personal wins or skills you’ve gained? (Brag on yourself—you’ve earned it! 😊) (optional)
                          <textarea
                            value={founder.accomplishments}
                            onChange={(e) => updateFounder(index, 'accomplishments', e.target.value)}
                            placeholder="Describe a challenge you overcame or a project you’re proud of."
                          ></textarea>
                        </label>
                        <div className="founder-actions-inline">
                          <button type="button" onClick={() => saveFounder(index)}>Save Team Member</button>
                          <button type="button" onClick={() => removeFounder(index)}>Delete Team Member</button>
                        </div>
                      </>
                    )}
                  </div>
                ))}
                {!formData.founders.some(f => !f.saved) && (
                  <button type="button" onClick={addFounder}>
                    Add New Team Member
                  </button>
                )}
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div className="form-step">
              <h3>Step 2: Project Details</h3>
              <label>
              🎪 What's your project called?
(Or your company name, if you have one! 🎉)
                <input
                  type="text"
                  value={formData.companyInfo.companyName}
                  onChange={(e) => handleChange('companyInfo', 'companyName', e.target.value)}
                  required
                />
              </label>
              <label>
              📝 Brief Description
              (Tell us in 1-2 sentences what you're making - make it snappy! ⚡)
                <input
                  type="text"
                  value={formData.companyInfo.shortDescription}
                  onChange={(e) => handleChange('companyInfo', 'shortDescription', e.target.value)}
                  required
                />
              </label>
              <label>
              🌐 Your Website
              (Got a website? Drop the link here! No worries if not. 😊)
                <input
                  type="url"
                  value={formData.companyInfo.companyUrl}
                  onChange={(e) => handleChange('companyInfo', 'companyUrl', e.target.value)}
                />
              </label>
              <label>
              Detail Description (The exciting part!)
What are you creating? 🎨
(Is it a game? An app? A robot? A secret potion? Tell us all about your awesome idea!)

How does it work? ⚙️
(Will it make people laugh? Teach something new? Solve a problem? How will kids use it?)
                <textarea
                  value={formData.companyInfo.description}
                  onChange={(e) => handleChange('companyInfo', 'description', e.target.value)}
                  required
                ></textarea>
              </label>
            </div>
          )}
          {currentStep === 3 && (
            <div className="form-step">
              <h3>Step 3: Current Progress</h3>
              <label>
              ✨ Describe your current progress:
                <textarea
                  value={formData.progress.progressDetails}
                  onChange={(e) => handleChange('progress', 'progressDetails', e.target.value)}
                  required
                ></textarea>
              </label>
              <label>
              How long have you been working on this? ⏳
(Days? Weeks? Forever?! 😆)
                <input
                  type="text"
                  value={formData.progress.duration}
                  onChange={(e) => handleChange('progress', 'duration', e.target.value)}
                  required
                />
              </label>
              <label>
              What are you using to build your project? 🛠️
(Tools, apps, gadgets—anything cool! 💻📱🔧)
                <input
                  type="text"
                  value={formData.progress.techStack}
                  onChange={(e) => handleChange('progress', 'techStack', e.target.value)}
                  required
                />
              </label>
              <label>
              Are people using or testing your project? 👥
(Friends, classmates, online? Tell us how it’s going! 🎉😅)
                <select
                  value={formData.progress.userAdoption}
                  onChange={(e) => handleChange('progress', 'userAdoption', e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </label>
              <label>
              Have you made any money from your project yet? 💸
(No worries if not—just curious! 😊)
                <select
                  value={formData.progress.revenueStatus}
                  onChange={(e) => handleChange('progress', 'revenueStatus', e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </label>
              <label>
              Anything else you want to share about your project? 🗨️
(Big wins 🏆, funny fails 🤦, or cool ideas 💡—we’re all ears! 👂)
                <textarea
                  value={formData.progress.additionalContext}
                  onChange={(e) => handleChange('progress', 'additionalContext', e.target.value)}
                  required
                ></textarea>
              </label>
            </div>
          )}
          {currentStep === 4 && (
            <div className="form-step">
              <h3>Step 4: Idea Overview</h3>
              <label>
              What made you choose this idea—personal passion, a problem you spotted, or just pure fun? 😊
                <textarea
                  value={formData.idea.ideaDetails}
                  onChange={(e) => handleChange('idea', 'ideaDetails', e.target.value)}
                  required
                ></textarea>
              </label>
              <label>
              Who are your competitors? 🏁
              That is, Who’s doing something similar, and what makes YOUR version special? ✨🔥"
                <textarea
                  value={formData.idea.competitors}
                  onChange={(e) => handleChange('idea', 'competitors', e.target.value)}
                  required
                ></textarea>
              </label>
              <label>
              Could your project make money? 💰
(Lemonade stand style 🍋, app downloads 📲, or just pure passion—all answers are cool! 😎)
                <input
                  type="text"
                  value={formData.idea.revenueModel}
                  onChange={(e) => handleChange('idea', 'revenueModel', e.target.value)}
                  required
                />
              </label>
              <label>
              What’s the best way to describe what you make? 🎨
(Like games 🎮, learning apps 📚, toys 🧸, or something else? 🌟)
                <input
                  type="text"
                  value={formData.idea.category}
                  onChange={(e) => handleChange('idea', 'category', e.target.value)}
                  required
                />
              </label>
              <label>
              Got other cool ideas? Share them below—you might have just the thing we love to support! 🚀
                <textarea
                  value={formData.idea.otherIdeas}
                  onChange={(e) => handleChange('idea', 'otherIdeas', e.target.value)}
                ></textarea>
              </label>
            </div>
          )}
          <div className="form-navigation">
            {currentStep > 1 && (
              <button type="button" onClick={prevStep}>Back</button>
            )}
            {currentStep < 4 && (
              <button type="button" onClick={nextStep}>Next</button>
            )}
            {currentStep === 4 && (
              <>
                <button type="submit">Submit Application</button>
                <button type="button" onClick={handleSaveForLater}>Save for Later</button>
              </>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

export default ApplicationForm;
