// src/components/PrivacyPolicy.js
import React from "react";
import "./StaticPage.css";
import Footer from "./Footer";

function PrivacyPolicy() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page">
        <h1>Privacy Policy</h1>

        <p>
          At <strong>YHatch</strong>, we are committed to protecting the privacy and personal data of our users, particularly youth and teen founders who are part of our startup community. This Privacy Policy outlines how we collect, use, store, disclose, and safeguard your information when you access or use our platform, website, services, and tools.
        </p>

        <h2>1. Scope</h2>
        <p>
          This Privacy Policy applies to all users of the YHatch platform, including applicants, registered members, guardians, and visitors. By accessing or using any part of YHatch, you consent to the practices described herein.
        </p>

        <h2>2. Information We Collect</h2>
        <p>We collect various types of information, including:</p>
        <ul>
          <li><strong>Personal Information:</strong> Full name, email address, phone number, age, location, and demographic details provided during account creation or application.</li>
          <li><strong>Application Information:</strong> Team composition, founder profiles, educational background, startup details, uploaded materials, and pitch data submitted as part of your application.</li>
          <li><strong>Guardian and Parental Information:</strong> For users under the age of 18, we may collect parental or guardian contact information to comply with youth data protection standards.</li>
          <li><strong>Usage Data:</strong> IP address, browser type, device type, referral pages, pages visited, and time spent on the platform (collected automatically via cookies and tracking technologies).</li>
          <li><strong>Communications:</strong> Messages, inquiries, feedback, and correspondence you send to us via email or through support forms.</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li>To create and manage your account and application profile.</li>
          <li>To review and evaluate applications and participation eligibility.</li>
          <li>To provide customized resources, feedback, mentorship, and notifications.</li>
          <li>To improve platform performance, usability, and content offerings.</li>
          <li>To comply with applicable legal requirements and youth protection laws.</li>
          <li>To respond to your inquiries and provide customer support.</li>
        </ul>

        <h2>4. Protection of Youth and Minor Users</h2>
        <p>
          YHatch is designed with a strong commitment to safeguarding young users. For participants under the age of 18, we implement the following safeguards:
        </p>
        <ul>
          <li>Collection of parental or guardian contact information for notification or consent.</li>
          <li>Restricted use and display of personal details in public-facing features.</li>
          <li>No sale or sharing of youth data with third parties for marketing purposes.</li>
          <li>Internal access controls to limit exposure of sensitive information.</li>
        </ul>

        <h2>5. Information Sharing and Disclosure</h2>
        <p>
          We may share your information in limited and secure circumstances:
        </p>
        <ul>
          <li>With authorized program staff, mentors, or evaluators involved in the application review process.</li>
          <li>With third-party service providers that support our operations (e.g., hosting, analytics, email services) under strict confidentiality agreements.</li>
          <li>With legal authorities, regulators, or law enforcement, if required by law, court order, or to protect the safety of users and the platform.</li>
        </ul>

        <h2>6. Data Security</h2>
        <p>
          We maintain appropriate administrative, technical, and physical safeguards to protect your personal information. These include data encryption, secure servers, access control mechanisms, and staff training on privacy best practices. However, no system can be guaranteed to be 100% secure. Users are also responsible for maintaining the confidentiality of their own login credentials.
        </p>

        <h2>7. Cookies and Tracking Technologies</h2>
        <p>
          YHatch uses cookies and similar technologies to enhance your experience, analyze usage, and customize content. You can modify your browser settings to disable cookies, but doing so may limit certain features of the platform.
        </p>

        <h2>8. Data Retention</h2>
        <p>
          We retain your information for as long as necessary to fulfill the purposes for which it was collected, including legal, operational, and reporting requirements. If you request deletion of your account, we will remove personal data except where retention is required by law.
        </p>

        <h2>9. Access and Control Over Your Information</h2>
        <p>
          You have the right to access, update, correct, or request the deletion of your personal data. To exercise any of these rights, please email us at <a href="mailto:support@yhatch.com">support@yhatch.com</a>. We will respond within a reasonable timeframe and in accordance with applicable privacy regulations.
        </p>

        <h2>10. Third-Party Services and External Links</h2>
        <p>
          YHatch may include links to third-party websites or services. We are not responsible for the privacy practices or content of such external platforms. We encourage users to review the privacy policies of third parties before providing personal information.
        </p>

        <h2>11. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect legal changes, platform updates, or user feedback. Material changes will be communicated via email or notifications within the platform. Continued use of YHatch after such updates constitutes your agreement to the revised policy.
        </p>

        <h2>12. Contact Information</h2>
        <p>
          If you have questions, concerns, or requests related to this Privacy Policy or your data rights, please contact us:
        </p>
        <p>
          <strong>Email:</strong> <a href="mailto:support@yhatch.com">support@yhatch.com</a>
        </p>

        <h2>Effective Date</h2>
        <p>This policy was last updated on March 31, 2025.</p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
