// src/components/admin/AdminConcepts.js
import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { useHistory } from "react-router-dom";
import "./AdminStyles.css";

function AdminConcepts() {
  const [concepts, setConcepts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const unsubscribeConcepts = firestore
      .collection("projectConcepts")
      .onSnapshot(
        (snapshot) => {
          const fetchedConcepts = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setConcepts(fetchedConcepts);
        },
        (error) => console.error("Error fetching concepts:", error)
      );
    return () => unsubscribeConcepts();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Delete this concept?")) {
      try {
        await firestore.collection("projectConcepts").doc(id).delete();
        alert("Concept deleted successfully");
      } catch (error) {
        console.error("Delete error:", error);
        alert("Error deleting concept");
      }
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp?.seconds) return "N/A";
    return new Date(timestamp.seconds * 1000).toLocaleDateString();
  };

  return (
    <div className="admin-section">
      <h2>Project Concepts ({concepts.length}) </h2>
      {concepts.length === 0 ? (
        <p>No concepts found.</p>
      ) : (
        <div className="table-container">
          <table className="admin-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Project Title</th>
                <th>Last Updated</th>
                <th>Contact Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {concepts.map((concept) => (
                <tr key={concept.id}>
                  <td>{concept.id.slice(0, 8)}...</td>
                  <td>{concept.projectTitle || "Untitled Concept"}</td>
                  <td>{formatDate(concept.updatedAt)}</td>
                  <td>{concept.contactEmail || "N/A"}</td>
                  <td>
                    <button
                      className="action-btn view-btn"
                      onClick={() => history.push(`/admin/concept/${concept.id}`)}
                    >
                      <i className="fas fa-eye"></i>
                    </button>
                    <button
                      className="action-btn delete-btn"
                      onClick={() => handleDelete(concept.id)}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AdminConcepts;
