import React, { useEffect, useState } from 'react';
import { firestore, auth } from '../firebase';
import { useHistory } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import './MyApplications.css';

function MyApplications() {
  const [applications, setApplications] = useState([]);
  const [concepts, setConcepts] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) {
        history.push('/login');
        return;
      }

      try {
        // Fetch user profile
        const userDoc = await firestore.collection('users').doc(user.uid).get();
        if (userDoc.exists) setUserData(userDoc.data());

        // Applications listener
        const unsubscribeApps = firestore.collection('applications')
          .where('userId', '==', user.uid)
          .onSnapshot(snapshot => {
            const apps = snapshot.docs.map(doc => ({
              id: doc.id,
              type: 'application',
              ...doc.data(),
              founders: doc.data().founders || [],
              createdAt: doc.data().createdAt?.toDate(),
              updatedAt: doc.data().updatedAt?.toDate()
            }));
            setApplications(apps);
            setLoading(false);
          });

        // Concepts listener
        const unsubscribeConcepts = firestore.collection('projectConcepts')
          .where('userId', '==', user.uid)
          .onSnapshot(snapshot => {
            const concepts = snapshot.docs.map(doc => ({
              id: doc.id,
              type: 'concept',
              ...doc.data(),
              teamMembers: doc.data().teamMembers || [],
              createdAt: doc.data().createdAt?.toDate(),
              updatedAt: doc.data().updatedAt?.toDate()
            }));
            setConcepts(concepts);
            setLoading(false);
          });

        return () => {
          unsubscribeApps();
          unsubscribeConcepts();
        };
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [history]);

  const handleLogout = async () => {
    await auth.signOut();
    history.push('/');
  };

  const handleEdit = (item) => {
    const path = item.type === 'application' 
      ? `/application/edit/${item.id}`
      : `/submit-concept/${item.id}`;

    history.push({
      pathname: path,
      state: { [item.type === 'application' ? 'applicationData' : 'conceptData']: item }
    });
  };

  const handleDelete = async (id, type) => {
    if (!window.confirm(`Delete this ${type}?`)) return;
    
    try {
      const collection = type === 'application' ? 'applications' : 'projectConcepts';
      await firestore.collection(collection).doc(id).delete();
      alert(`${type} deleted`);
    } catch (error) {
      console.error("Delete error:", error);
      alert(`Error deleting ${type}`);
    }
  };

  const formatDate = (date) => 
    date?.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || 'N/A';

  return (
    <DashboardLayout onLogout={handleLogout} user={userData}>
      <div className="applications-container">
        <h2>My Submissions</h2>
        
        {loading ? (
          <div className="loading">Loading submissions...</div>
        ) : (
          <>
            {/* Applications Section */}
            {applications.length > 0 && (
              <div className="section">
                <h3>Full Applications ({applications.length})</h3>
                <div className="applications-list">
                  {applications.map(app => (
                    <div key={app.id} className="card">
                      <div className="card-header">
                        <h4>{app.projectTitle || 'Untitled Application'}</h4>
                        <span className={`status ${app.status?.toLowerCase()}`}>
                          {app.status || 'Draft'}
                        </span>
                      </div>
                      <div className="card-body">
                      <p>  Team: {app.founders?.[0]?.firstName + ' ' + app.founders?.[0]?.lastName} </p>
                        <p>Last updated: {formatDate(app.updatedAt)}</p>
                      </div>
                      <div className="card-actions">
                        <button onClick={() => handleEdit(app)}>Edit</button>
                        <button onClick={() => handleDelete(app.id, 'application')}>Delete</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Concepts Section */}
            {concepts.length > 0 && (
              <div className="section">
                <h3>Project Concepts ({concepts.length})</h3>
                <div className="applications-list">
                  {concepts.map(concept => (
                    <div key={concept.id} className="card">
                      <div className="card-header">
                        <h4>{concept.projectTitle || 'Untitled Concept'}</h4>
                        <span className="status concept">Concept</span>
                      </div>
                      <div className="card-body">
                        <p>  Team: {concept.founders?.[0]?.firstName + ' ' + concept.founders?.[0]?.lastName} </p>
                        <p>Last updated: {formatDate(concept.updatedAt)}</p>
                      </div>
                      <div className="card-actions">
                        <button onClick={() => handleEdit(concept)}>Edit</button>
                        <button onClick={() => handleDelete(concept.id, 'concept')}>Delete</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Empty State */}
            {applications.length === 0 && concepts.length === 0 && (
              <div className="empty-state">
                <p>No submissions found</p>
                <div className="actions">
                  <button onClick={() => history.push('/application/new')}>
                    New Application
                  </button>
                  <button onClick={() => history.push('/submit-concept')}>
                    New Concept
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </DashboardLayout>
  );
}

export default MyApplications;