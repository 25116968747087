// src/components/Footer.js
import React, { useState } from 'react';
import firebase from '../firebase';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage('Please enter a valid email.');
      return;
    }
    try {
      await firebase.firestore().collection('subscribers').add({ email });
      setMessage('Thanks for subscribing!');
      setEmail('');
    } catch (error) {
      console.error('Error adding email:', error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>About Us</h4>
          <ul>
            <li><a href="/about" target="_blank" rel="noopener noreferrer">About YHatch</a></li>
            <li><a href="/contact" target="_blank" rel="noopener noreferrer">Contact</a></li>
            <li><a href="/investors" target="_blank" rel="noopener noreferrer">Investors</a></li>
            <li><a href="/press" target="_blank" rel="noopener noreferrer">Press & Media</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Legal Info</h4>
          <ul>
            <li><a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
            <li><a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
            <li><a href="/disclaimers" target="_blank" rel="noopener noreferrer">Disclaimers</a></li>
            <li><a href="/cookie" target="_blank" rel="noopener noreferrer">Cookies</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Support</h4>
          <ul>
            <li><a href="/faq" target="_blank" rel="noopener noreferrer">FAQ</a></li>
            <li><a href="/links" target="_blank" rel="noopener noreferrer">Helpful Links</a></li>
          </ul>
        </div>

        {/* New Subscription Section */}
        <div className="footer-section">
          <h4>Subscribe to our Newsletter</h4>
          <form onSubmit={handleSubmit} className="subscription-form">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Subscribe</button>
          </form>
          {message && <p className="subscription-message">{message}</p>}
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {currentYear} YHatch. All rights reserved.</p>
        <div className="social-icons">
          <a href="https://facebook.com" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://instagram.com" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://linkedin.com" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href="https://youtube.com" aria-label="YouTube" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
