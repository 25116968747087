import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { firestore } from "../../firebase";
import "./AdminStyles.css";

function AdminConceptDetails() {
  const { id } = useParams();
  const history = useHistory();
  const [conceptData, setConceptData] = useState(null);

  useEffect(() => {
    const unsubscribe = firestore.collection("projectConcepts").doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setConceptData(doc.data());
        } else {
          alert("Concept not found");
          history.push("/admin");
        }
      }, (error) => {
        console.error("Error loading concept:", error);
        alert("Error loading concept details");
      });
    
    return () => unsubscribe();
  }, [id, history]);

  if (!conceptData) return <div className="admin-section">Loading concept details...</div>;

  return (
    <div className="admin-section">
      <h2>Concept Details</h2>
      
      <div className="metadata-section">
        <div className="info-row">
          <label>Concept ID:</label>
          <span>{id}</span>
        </div>
        <div className="info-row">
          <label>Project Title:</label>
          <span>{conceptData.projectTitle || "N/A"}</span>
        </div>
        <div className="info-row">
          <label>Contact Email:</label>
          <span>{conceptData.contactEmail || "N/A"}</span>
        </div>
        <div className="info-row">
          <label>Last Updated:</label>
          <span>
            {conceptData.updatedAt?.seconds ? 
              new Date(conceptData.updatedAt.seconds * 1000).toLocaleString() : "N/A"}
          </span>
        </div>
      </div>

      <div className="section">
        <h3>Concept Description</h3>
        <p>{conceptData.briefDescription || "No description provided"}</p>
      </div>

      <div className="section">
        <h3>Team Members ({conceptData.teamMembers?.length || 0})</h3>
        {conceptData.teamMembers?.map((member, index) => (
          <div key={index} className="team-member-card">
            <h4>{member.firstName} {member.lastName}</h4>
            <div className="info-grid">
              <div className="info-row">
                <label>Email:</label>
                <span>{member.email || "N/A"}</span>
              </div>
              <div className="info-row">
                <label>Role:</label>
                <span>{member.role || "N/A"}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button 
        onClick={() => history.push("/admin")}
        className="back-button"
      >
        Return to Dashboard
      </button>
    </div>
  );
}

export default AdminConceptDetails;