// src/components/FAQ.js
import React from "react";
import "./StaticPage.css";
import Footer from "./Footer";

function FAQ() {
  return (
    <div className="static-page-wrapper">
      <div className="static-page">
        <h1>🙋‍♀️ Frequently Asked Questions</h1>

        <h2>🌟 What is YHatch?</h2>
        <p>
          YHatch is a launchpad for young dreamers, creators, and innovators. We're a startup accelerator made just for youth and teens — offering mentorship, resources, community, and opportunities to turn your ideas into real-world ventures.
        </p>

        <h2>🚀 How do I apply?</h2>
        <p>
          Easy! Create an account, log in, and head to your dashboard to start a new application. Fill out the details about your venture and your team. You can save and come back anytime before submitting.
        </p>

        <h2>💸 Is there a fee to join?</h2>
        <p>
          Nope! YHatch is 100% free for eligible young entrepreneurs. We believe cost should never be a barrier to innovation.
        </p>

        <h2>👥 Who can apply?</h2>
        <p>
          Anyone between the ages of 13–19 with a startup idea, project, or prototype is welcome! Whether you're working solo or with a team, YHatch is here to support you.
        </p>

        <h2>📅 When is the application deadline?</h2>
        <p>
          Our program runs in cycles. Check the homepage or your dashboard for the current application period and deadlines.
        </p>

        <h2>💡 What kinds of ideas are you looking for?</h2>
        <p>
          We love all types of innovation — tech, social impact, art, fashion, education, science, sustainability — you name it. As long as it's creative, purposeful, and youth-driven, you're on the right track.
        </p>

        <h2>🙌 What kind of support do participants receive?</h2>
        <p>
          YHatch offers personalized mentorship, expert sessions, community events, funding opportunities, and access to tools and platforms to help you build and grow.
        </p>

        <h2>🧠 I’ve never started a business before. Can I still join?</h2>
        <p>
          Absolutely! YHatch is designed to support you no matter where you're starting from. Whether it’s just an idea or a full project, we’ll help you take the next step.
        </p>

        <h2>🏆 Is this a competition?</h2>
        <p>
          Nope — it's a collaborative learning experience. We focus on growth, not rankings. That said, there may be optional pitch opportunities and rewards along the way!
        </p>

        <h2>🧑‍💻 Can I work on my startup while in school?</h2>
        <p>
          Totally. YHatch is flexible and understands your time is precious. Our program is built to work around school schedules, homework, and extracurriculars.
        </p>

        <h2>📬 I still have questions!</h2>
        <p>
          We're happy to help! Just shoot us an email at{" "}
          <a href="mailto:support@yhatch.com">support@yhatch.com</a> or send us a DM on social. We’re real humans who care. 💙
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
