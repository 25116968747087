// src/components/Signup.js
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import Footer from './Footer';
import './Signup.css';

function Signup() {
  const [firstName, setFirstName]       = useState('');
  const [lastName, setLastName]         = useState('');
  const [email, setEmail]               = useState('');
  const [password, setPassword]         = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const history = useHistory();

  const validatePassword = (pwd) => {
    // At least 8 characters, one letter, one number, one special character
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return regex.test(pwd);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    if (!validatePassword(password)) {
      alert("Password must be at least 8 characters, include a letter, a number, and a special character.");
      return;
    }
    try {
      const { user } = await auth.createUserWithEmailAndPassword(email, password);
      await firestore.collection('users').doc(user.uid).set({
        firstName,
        lastName,
        email
      });
      alert("Signup successful! You can now login.");
      history.push('/login');
    } catch (error) {
      console.error("Error signing up: ", error);
      alert(error.message);
    }
  };

  return (
    <div className="signup-page">
      <div className="signup-container">
        <h2>Signup for YHatch</h2>
        <form onSubmit={handleSignup}>
          <input 
            type="text" 
            placeholder="First Name" 
            value={firstName} 
            onChange={(e) => setFirstName(e.target.value)} 
            required 
          />
          <input 
            type="text" 
            placeholder="Last Name" 
            value={lastName} 
            onChange={(e) => setLastName(e.target.value)} 
            required 
          />
          <input 
            type="email" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          <input 
            type="password" 
            placeholder="Password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <input 
            type="password" 
            placeholder="Confirm Password" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
          <button type="submit">Signup</button>
        </form>
        <p>
          Already have an account? <Link to="/login">Login here</Link>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Signup;
