// src/components/admin/AdminSubscribers.js
import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import "./AdminStyles.css";
import "./AdminDashboard.css";

function AdminSubscribers() {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Subscribe to realtime updates from the "subscribers" collection
    const unsubscribe = firebase
      .firestore()
      .collection("subscribers")
      .onSnapshot(
        (snapshot) => {
          const subs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setSubscribers(subs);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching subscribers: ", error);
          setLoading(false);
        }
      );
    return () => unsubscribe();
  }, []);

  return (
    <div className="admin-page">
      <h2>Subscribers ({subscribers.length})</h2>
      {loading ? (
        <p>Loading...</p>
      ) : subscribers.length > 0 ? (
        <table className="admin-table">
          <thead>
            <tr>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {subscribers.map((subscriber) => (
              <tr key={subscriber.id}>
                <td>{subscriber.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No subscribers found.</p>
      )}
    </div>
  );
}

export default AdminSubscribers;
