// src/components/admin/AdminDashboard.js
import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import AdminApplications from "./AdminApplications";
import AdminConcepts from "./AdminConcepts";
import AdminApplicationDetails from "./AdminApplicationDetails";
import AdminConceptDetails from "./AdminConceptDetails";
import AdminSubscribers from "./AdminSubscribers";
import "./AdminStyles.css";
import { auth } from "../../firebase";

function AdminDashboard() {
  const [admin, setAdmin] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      if (user.email === "lijuanmo@gmail.com") {
        setAdmin(user);
      } else {
        alert("You are not authorized to access the admin dashboard.");
        history.push("/");
      }
    } else {
      history.push("/");
    }
  }, [history]);

  if (!admin) return null; // Optionally show a loading indicator

  return (
    <div className="admin-dashboard">
      <AdminSidebar />
      <div className="admin-content">
        <Switch>
          <Route exact path="/admin">
            <Redirect to="/admin/applications" />
          </Route>
          <Route path="/admin/applications" component={AdminApplications} />
          <Route path="/admin/application/:id" component={AdminApplicationDetails} />
          <Route path="/admin/concepts" component={AdminConcepts} />
          <Route path="/admin/concept/:id" component={AdminConceptDetails} />
          <Route path="/admin/subscribers" component={AdminSubscribers} />
          <Route path="*">
            <h2>Access Denied</h2>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default AdminDashboard;
