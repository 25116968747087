// src/components/LeftMenu.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FiUser,
  FiFileText,
  FiFilePlus,
  FiLogOut,
  FiHome,
  FiMenu,
  FiZap 
} from 'react-icons/fi';
import './LeftMenu.css';



function LeftMenu({ onLogout, user }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const closeSidebar = () => setIsSidebarOpen(false);

  return (
    <div className="left-menu-layout">
      <div className="mobile-header">
        <button className="hamburger-button" onClick={toggleSidebar}>
          <FiMenu />
        </button>
        <span>YHatch Dashboard</span>
      </div>

      <aside className={`left-menu ${isSidebarOpen ? 'open' : ''}`}>
        <div className="menu-header">
          <h1 className="logo">
            <i className="fas fa-rocket"></i> YHatch
          </h1>
          {user && (
            <p className="welcome">
              <i className="fas fa-user"></i> Hi, {user.firstName}
            </p>
          )}
        </div>

        <ul className="menu-links">
          <li>
            <Link to="/home" onClick={closeSidebar}>
              <FiHome /> Home
            </Link>
          </li>
          <li>
            <Link to="/profile" onClick={closeSidebar}>
              <FiUser /> Profile
            </Link>
          </li>
          <li>
            <Link to="/applications" onClick={closeSidebar}>
              <FiFileText /> My Applications
            </Link>
          </li>
          <li>
            <Link to="/application/new" onClick={closeSidebar}>
              <FiFilePlus /> New Application
            </Link>
          </li>
          <li>
            <Link to="/submit-concept" onClick={closeSidebar}>
              <FiZap /> Submit Concept
            </Link>
          </li>
        </ul>

        <div className="logout">
          <button onClick={() => { onLogout(); closeSidebar(); }}>
            <FiLogOut /> Logout
          </button>
        </div>
      </aside>

      {/* Backdrop for closing sidebar on mobile */}
      {isSidebarOpen && <div className="backdrop" onClick={closeSidebar}></div>}
    </div>
  );
}

export default LeftMenu;
