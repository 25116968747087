// src/components/admin/AdminSidebar.js
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { auth } from "../../firebase";
import "./AdminStyles.css";
import "./AdminDashboard.css";

function AdminSidebar() {
  const history = useHistory();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      history.push("/");
    } catch (error) {
      console.error("Error logging out:", error);
      alert(error.message);
    }
  };

  return (
    <div className="admin-sidebar">
      <h2>
        <i className="fas fa-cogs"></i> Admin Panel
      </h2>
      <nav>
        <ul>
          <li>
            <NavLink to="/admin/applications" activeClassName="active">
              <i className="fas fa-file-alt"></i> Applications
            </NavLink>
          </li>
          <li>
            <NavLink to="/admin/concepts" activeClassName="active">
              <i className="fas fa-file-alt"></i> Concepts
            </NavLink>
          </li>          
          <li>
            <NavLink to="/admin/subscribers" activeClassName="active">
              <i className="fas fa-envelope"></i> Subscribers
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="admin-logout">
        <button onClick={handleLogout}>
          <i className="fas fa-sign-out-alt"></i> Logout
        </button>
      </div>
    </div>
  );
}

export default AdminSidebar;
