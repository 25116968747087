// src/components/DashboardLayout.js
import React, { useState } from 'react';
import LeftMenu from './LeftMenu';
import Footer from './Footer';
import './DashboardLayout.css';

function DashboardLayout({ children, onLogout, user }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  return (
    <>
      {/* Toggle button for mobile */}
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>

      {/* Sidebar */}
      <div className={`home-wrapper`}>
        <aside className={`sidebar ${menuOpen ? 'open' : ''}`}>
          <LeftMenu onLogout={onLogout} user={user} closeMenu={closeMenu} />
        </aside>

        {/* Optional background overlay for mobile */}
        {menuOpen && <div className="overlay" onClick={closeMenu}></div>}

        {/* Main content */}
        <div className="main-layout">
          <main className="content">{children}</main>
        </div>
      </div>

      {/* Footer always at bottom */}
      <Footer />
    </>
  );
}

export default DashboardLayout;
