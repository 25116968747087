// src/components/Login.js
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth } from "../firebase";
import Footer from "./Footer";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await auth.signInWithEmailAndPassword(email, password);
      const user = userCredential.user;
      // Only admin (lijuanmo@gmail.com) goes to admin dashboard.
      if (user.email === "lijuanmo@gmail.com") {
        history.push("/admin");
      } else {
        history.push("/home");
      }
    } catch (error) {
      console.error("Error logging in: ", error);
      alert(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert("Please enter your email in the email field above.");
      return;
    }
    try {
      await auth.sendPasswordResetEmail(email);
      alert("Password reset email sent. Please check your inbox.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert(error.message);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login to YHatch</h2>
        <form onSubmit={handleLogin}>
          <input 
            type="email" 
            placeholder="Email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          <input 
            type="password" 
            placeholder="Password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <button type="submit">Login</button>
        </form>
        <p>
          {/* Replace the <a> tag with a button */}
          <button 
            type="button" 
            onClick={handleForgotPassword}
            className="forgot-password-button"
          >
            Forgot Password?
          </button>
        </p>
        <p>
          Don't have an account? <Link to="/signup">Signup here</Link>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
