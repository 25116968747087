// src/components/Profile.js
import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import DashboardLayout from './DashboardLayout';
import './Profile.css';

function Profile() {
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', email: '' });
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const doc = await firestore.collection('users').doc(user.uid).get();
        if (doc.exists) {
          const data = doc.data();
          setUserInfo({ firstName: data.firstName, lastName: data.lastName, email: user.email });
        }
      }
      setLoading(false);
    };
    fetchUserData();
  }, []);

  const handleLogout = async () => {
    await auth.signOut();
    window.location.href = '/';
  };

  if (loading) return <p>Loading profile...</p>;

  return (
    <DashboardLayout onLogout={handleLogout} user={userInfo}>
      <div className="profile-container">
        <h2>My Profile</h2>
        <form>
          <label>First Name</label>
          <input type="text" value={userInfo.firstName} disabled />

          <label>Last Name</label>
          <input type="text" value={userInfo.lastName} disabled />

          <label>Email</label>
          <input type="email" value={userInfo.email} disabled />
        </form>
        <button onClick={async () => {
          await auth.sendPasswordResetEmail(userInfo.email);
          setMessage('Password reset email sent!');
        }}>
          Send Password Reset Email
        </button>
        {message && <p className="profile-message">{message}</p>}
      </div>
    </DashboardLayout>
  );
}

export default Profile;
