// src/components/admin/AdminApplications.js
import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { useHistory } from "react-router-dom";
import "./AdminStyles.css";

function AdminApplications() {
  const [applications, setApplications] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const unsubscribeApps = firestore
      .collection("applications")
      .onSnapshot(
        (snapshot) => {
          const apps = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setApplications(apps);
        },
        (error) => console.error("Error fetching applications:", error)
      );

    return () => unsubscribeApps();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Delete this application?")) {
      try {
        await firestore.collection("applications").doc(id).delete();
        alert("Application deleted successfully");
      } catch (error) {
        console.error("Delete error:", error);
        alert("Error deleting application");
      }
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp?.seconds) return "N/A";
    return new Date(timestamp.seconds * 1000).toLocaleDateString();
  };

  return (
    <div className="admin-section">
      <h2>Applications ({applications.length})</h2>
      {applications.length === 0 ? (
        <p>No applications found.</p>
      ) : (
        <div className="table-container">
          <table className="admin-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Project Title</th>
                <th>Last Updated</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {applications.map((app) => (
                <tr key={app.id}>
                  <td>{app.id.slice(0, 8)}...</td>
                  <td>{app.projectTitle || "Untitled Project"}</td>
                  <td>{formatDate(app.updatedAt)}</td>
                  <td>{app.status || "In Progress"}</td>
                  <td>
                    <button
                      className="action-btn view-btn"
                      onClick={() => history.push(`/admin/application/${app.id}`)}
                    >
                      <i className="fas fa-eye"></i>
                    </button>
                    <button
                      className="action-btn delete-btn"
                      onClick={() => handleDelete(app.id)}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AdminApplications;
