import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import './Homepage.css';

function Homepage() {
  return (
    <div className="homepage-container">
      <header className="hero">
        <h1>Welcome to YHatch</h1>
        <p>
          Your launchpad for youth entrepreneurship and innovation. Join us to kickstart your startup journey and turn your ideas into reality.
        </p>
        <div className="hero-buttons">
          <Link to="/login" className="btn">Login</Link>
          <Link to="/signup" className="btn secondary">Signup</Link>
        </div>
      </header>
      <section className="intro">
        <h2>About YHatch</h2>
        <p>
          YHatch is dedicated to empowering young founders by providing resources, mentorship, and community support. Whether you're just starting out or looking to scale your startup, we're here to help.
        </p>
      </section>
      <Footer />
    </div>
  );
}

export default Homepage;
